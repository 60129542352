<template>
  <div class="apply-note_container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ApplyNote',
}
</script>

<style lang="scss">
@import "@/assets/scss/common-mixin-functions.scss";
@import "@/assets/scss/variables/variables-components";

.apply-note_container {
  background-color: $white;
  padding: 32px 60px;
  min-height: 100vh;
  .apply-note_header {
    padding-bottom: 8px;
    border-bottom: 1px solid $border-clr;
  }
  .apply-note_body {
    .apply-note_table_content {
      margin-top: 37px;
      max-height: 420px;
    }
    .total-container {
      border: 1px solid $border-clr;
      border-radius: 4px;
      padding: 24px;
      width: 35%;

      .subtotal-container {
        border-bottom: 1px solid $border-clr;
      }
    }

    .total-key-text {
      line-height: 19px;
      color: $order-info-val-clr;
      @include font-weight-and-size(600, 16px);
    }
    .total-value-text {
      line-height: 19px;
      color: $label-clr;
      @include font-weight-and-size(500, 16px);
    }
  }
  .apply-note_action_btns {
    margin-top: 200px;
  }
}
</style>
